import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { urls } from '@amzn/katal-platform-versions';
import { DOCUMENT } from '@angular/common'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'webapp';
  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
  }
  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.src = urls.prod;

    this._renderer2.appendChild(this._document.body, script);
  }
}
