import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { AcctContractService } from './api/acctContract.service';
import { AdminContractService } from './api/adminContract.service';
import { ApproverCCService } from './api/approverCC.service';
import { ApproverContractService } from './api/approverContract.service';
import { AuthService } from './api/auth.service';
import { BusinessLineService } from './api/businessLine.service';
import { ContractsService } from './api/contracts.service';
import { DiscussionForumService } from './api/discussionForum.service';
import { EntityConfirmationPipelineWorkflowService } from './api/entityConfirmationPipelineWorkflow.service';
import { FileService } from './api/file.service';
import { GroupService } from './api/group.service';
import { ImpactAreaService } from './api/impactArea.service';
import { MLService } from './api/mL.service';
import { ManagementService } from './api/management.service';
import { MetaService } from './api/meta.service';
import { NotifyService } from './api/notify.service';
import { OneWorkflowService } from './api/oneWorkflow.service';
import { QuestionsService } from './api/questions.service';
import { ReportingService } from './api/reporting.service';
import { SearchService } from './api/search.service';
import { SubmitterCCService } from './api/submitterCC.service';
import { SubmitterContractService } from './api/submitterContract.service';
import { UserService } from './api/user.service';
import { WorklistService } from './api/worklist.service';

@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [],
  exports:      [],
  providers: [
    AcctContractService,
    AdminContractService,
    ApproverCCService,
    ApproverContractService,
    AuthService,
    BusinessLineService,
    ContractsService,
    DiscussionForumService,
    EntityConfirmationPipelineWorkflowService,
    FileService,
    GroupService,
    ImpactAreaService,
    MLService,
    ManagementService,
    MetaService,
    NotifyService,
    OneWorkflowService,
    QuestionsService,
    ReportingService,
    SearchService,
    SubmitterCCService,
    SubmitterContractService,
    UserService,
    WorklistService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        }
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }
}
