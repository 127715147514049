/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AcctApprovalCommand } from '../model/acctApprovalCommand';
import { AcctCancelContractCommand } from '../model/acctCancelContractCommand';
import { AcctDenyApprovalCommand } from '../model/acctDenyApprovalCommand';
import { AcctInternalCancelContractCommand } from '../model/acctInternalCancelContractCommand';
import { FinalAcctApproveContractCommand } from '../model/finalAcctApproveContractCommand';
import { IAcctAdminContractOverview } from '../model/iAcctAdminContractOverview';
import { IAcctApprovalOverview } from '../model/iAcctApprovalOverview';
import { SubmitForAcctInternalApprovalCommand } from '../model/submitForAcctInternalApprovalCommand';
import { UpdateAcctAdminContractCommand } from '../model/updateAcctAdminContractCommand';
import { UpdateAcctRequestCommentCommand } from '../model/updateAcctRequestCommentCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AcctContractService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Approver approves a contract for all functional areas the approver is assigned to.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractApprovalApprovePut(command?: AcctApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractApprovalApprovePut(command?: AcctApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractApprovalApprovePut(command?: AcctApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractApprovalApprovePut(command?: AcctApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/AcctContract/approval/approve`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the approval contract overview. for approver and approver cc
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IAcctApprovalOverview>;
    public apiAcctContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAcctApprovalOverview>>;
    public apiAcctContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAcctApprovalOverview>>;
    public apiAcctContractApprovalByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAcctContractApprovalByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IAcctApprovalOverview>(`${this.basePath}/api/AcctContract/approval/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approver cancels a contract for all functional areas the approver is assigned to.   This will also update other approvers that share the same functioanl area.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractApprovalCancelPut(command?: AcctInternalCancelContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractApprovalCancelPut(command?: AcctInternalCancelContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractApprovalCancelPut(command?: AcctInternalCancelContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractApprovalCancelPut(command?: AcctInternalCancelContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/AcctContract/approval/cancel`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approver denies a contract for all functional areas the approver is assigned to.   This will also update other approvers that share the same functioanl area.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractApprovalDenyPut(command?: AcctDenyApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractApprovalDenyPut(command?: AcctDenyApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractApprovalDenyPut(command?: AcctDenyApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractApprovalDenyPut(command?: AcctDenyApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/AcctContract/approval/deny`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Admin can Cancels the contract.
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractDelete(command?: AcctCancelContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractDelete(command?: AcctCancelContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractDelete(command?: AcctCancelContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractDelete(command?: AcctCancelContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/AcctContract`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Final Approver to approve the contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractFinalApprovalPut(command?: FinalAcctApproveContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractFinalApprovalPut(command?: FinalAcctApproveContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractFinalApprovalPut(command?: FinalAcctApproveContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractFinalApprovalPut(command?: FinalAcctApproveContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/AcctContract/finalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the contract with all fields needed for the Admin edit screen.
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IAcctAdminContractOverview>;
    public apiAcctContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAcctAdminContractOverview>>;
    public apiAcctContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAcctAdminContractOverview>>;
    public apiAcctContractOverviewByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAcctContractOverviewByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IAcctAdminContractOverview>(`${this.basePath}/api/AcctContract/overview/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save draft for Admin initial review, final review and any other state
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractOverviewPatch(command?: UpdateAcctAdminContractCommand, observe?: 'body', reportProgress?: boolean): Observable<IAcctAdminContractOverview>;
    public apiAcctContractOverviewPatch(command?: UpdateAcctAdminContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAcctAdminContractOverview>>;
    public apiAcctContractOverviewPatch(command?: UpdateAcctAdminContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAcctAdminContractOverview>>;
    public apiAcctContractOverviewPatch(command?: UpdateAcctAdminContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<IAcctAdminContractOverview>(`${this.basePath}/api/AcctContract/overview`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an admin to update the RequestComment field.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractRequestCommentPatch(command?: UpdateAcctRequestCommentCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractRequestCommentPatch(command?: UpdateAcctRequestCommentCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractRequestCommentPatch(command?: UpdateAcctRequestCommentCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractRequestCommentPatch(command?: UpdateAcctRequestCommentCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/AcctContract/requestComment`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAcctContractSubmitForAcctInternalApprovalPut(command?: SubmitForAcctInternalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAcctContractSubmitForAcctInternalApprovalPut(command?: SubmitForAcctInternalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAcctContractSubmitForAcctInternalApprovalPut(command?: SubmitForAcctInternalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAcctContractSubmitForAcctInternalApprovalPut(command?: SubmitForAcctInternalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/AcctContract/submitForAcctInternalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
