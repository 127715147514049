/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IDateRange } from './iDateRange';


export interface AdvancedContractSearchCommand {
    approvers?: Array<string>;
    adminPOC?: string;
    closedOn?: IDateRange;
    contractKeyId?: string;
    contractPipelineId?: AdvancedContractSearchCommand.ContractPipelineIdEnum;
    contractSubPipelineId?: AdvancedContractSearchCommand.ContractSubPipelineIdEnum;
    contractTypes?: Array<number>;
    counterPartyGeoImpacts?: Array<number>;
    counterPartyId?: number;
    expediteReviewFlag?: boolean;
    geoImpacts?: Array<number>;
    impactAreas?: Array<number>;
    bizLines?: Array<number>;
    ingestionContractPipelineId?: AdvancedContractSearchCommand.IngestionContractPipelineIdEnum;
    involvedEntities?: Array<number>;
    requesterUserId?: string;
    statusGroupId?: AdvancedContractSearchCommand.StatusGroupIdEnum;
    statusId?: AdvancedContractSearchCommand.StatusIdEnum;
    submittedOn?: IDateRange;
    title?: string;
    globalResult?: boolean;
}
export namespace AdvancedContractSearchCommand {
    export type ContractPipelineIdEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    export const ContractPipelineIdEnum = {
        NUMBER_1: 1 as ContractPipelineIdEnum,
        NUMBER_2: 2 as ContractPipelineIdEnum,
        NUMBER_3: 3 as ContractPipelineIdEnum,
        NUMBER_4: 4 as ContractPipelineIdEnum,
        NUMBER_5: 5 as ContractPipelineIdEnum,
        NUMBER_6: 6 as ContractPipelineIdEnum,
        NUMBER_7: 7 as ContractPipelineIdEnum,
        NUMBER_8: 8 as ContractPipelineIdEnum,
        NUMBER_9: 9 as ContractPipelineIdEnum
    }
    export type ContractSubPipelineIdEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 16 | 17;
    export const ContractSubPipelineIdEnum = {
        NUMBER_1: 1 as ContractSubPipelineIdEnum,
        NUMBER_2: 2 as ContractSubPipelineIdEnum,
        NUMBER_3: 3 as ContractSubPipelineIdEnum,
        NUMBER_4: 4 as ContractSubPipelineIdEnum,
        NUMBER_5: 5 as ContractSubPipelineIdEnum,
        NUMBER_6: 6 as ContractSubPipelineIdEnum,
        NUMBER_7: 7 as ContractSubPipelineIdEnum,
        NUMBER_8: 8 as ContractSubPipelineIdEnum,
        NUMBER_9: 9 as ContractSubPipelineIdEnum,
        NUMBER_10: 10 as ContractSubPipelineIdEnum,
        NUMBER_11: 11 as ContractSubPipelineIdEnum,
        NUMBER_12: 12 as ContractSubPipelineIdEnum,
        NUMBER_13: 13 as ContractSubPipelineIdEnum,
        NUMBER_14: 14 as ContractSubPipelineIdEnum,
        NUMBER_16: 16 as ContractSubPipelineIdEnum,
        NUMBER_17: 17 as ContractSubPipelineIdEnum
    }
    export type IngestionContractPipelineIdEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    export const IngestionContractPipelineIdEnum = {
        NUMBER_1: 1 as IngestionContractPipelineIdEnum,
        NUMBER_2: 2 as IngestionContractPipelineIdEnum,
        NUMBER_3: 3 as IngestionContractPipelineIdEnum,
        NUMBER_4: 4 as IngestionContractPipelineIdEnum,
        NUMBER_5: 5 as IngestionContractPipelineIdEnum,
        NUMBER_6: 6 as IngestionContractPipelineIdEnum,
        NUMBER_7: 7 as IngestionContractPipelineIdEnum,
        NUMBER_8: 8 as IngestionContractPipelineIdEnum,
        NUMBER_9: 9 as IngestionContractPipelineIdEnum
    }
    export type StatusGroupIdEnum = 1 | 2 | 3 | 4 | 6 | -1;
    export const StatusGroupIdEnum = {
        NUMBER_1: 1 as StatusGroupIdEnum,
        NUMBER_2: 2 as StatusGroupIdEnum,
        NUMBER_3: 3 as StatusGroupIdEnum,
        NUMBER_4: 4 as StatusGroupIdEnum,
        NUMBER_6: 6 as StatusGroupIdEnum,
        NUMBER_MINUS_1: -1 as StatusGroupIdEnum
    }
    export type StatusIdEnum = 0 | 3 | 6 | 10 | 20 | 30 | 35 | 40 | 50 | 60 | 65 | 70 | 80 | 90;
    export const StatusIdEnum = {
        NUMBER_0: 0 as StatusIdEnum,
        NUMBER_3: 3 as StatusIdEnum,
        NUMBER_6: 6 as StatusIdEnum,
        NUMBER_10: 10 as StatusIdEnum,
        NUMBER_20: 20 as StatusIdEnum,
        NUMBER_30: 30 as StatusIdEnum,
        NUMBER_35: 35 as StatusIdEnum,
        NUMBER_40: 40 as StatusIdEnum,
        NUMBER_50: 50 as StatusIdEnum,
        NUMBER_60: 60 as StatusIdEnum,
        NUMBER_65: 65 as StatusIdEnum,
        NUMBER_70: 70 as StatusIdEnum,
        NUMBER_80: 80 as StatusIdEnum,
        NUMBER_90: 90 as StatusIdEnum
    }
}
