/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AddCounterPartyNameCommand } from '../model/addCounterPartyNameCommand';
import { IContractStatusFilter } from '../model/iContractStatusFilter';
import { IContractSubPipeLine } from '../model/iContractSubPipeLine';
import { ICounterParty } from '../model/iCounterParty';
import { IEntityLocal } from '../model/iEntityLocal';
import { IEnumGroup } from '../model/iEnumGroup';
import { IImpactLocationEnumView } from '../model/iImpactLocationEnumView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ITaxEnum } from '../model/iTaxEnum';


@Injectable()
export class MetaService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaContractStatusFilterGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IContractStatusFilter>>;
    public apiMetaContractStatusFilterGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IContractStatusFilter>>>;
    public apiMetaContractStatusFilterGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IContractStatusFilter>>>;
    public apiMetaContractStatusFilterGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IContractStatusFilter>>(`${this.basePath}/api/meta/contractStatusFilter`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractPipeLineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaContractSubPipeLineByContractPipeLineIdGet(contractPipeLineId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IContractSubPipeLine>>;
    public apiMetaContractSubPipeLineByContractPipeLineIdGet(contractPipeLineId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IContractSubPipeLine>>>;
    public apiMetaContractSubPipeLineByContractPipeLineIdGet(contractPipeLineId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IContractSubPipeLine>>>;
    public apiMetaContractSubPipeLineByContractPipeLineIdGet(contractPipeLineId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractPipeLineId === null || contractPipeLineId === undefined) {
            throw new Error('Required parameter contractPipeLineId was null or undefined when calling apiMetaContractSubPipeLineByContractPipeLineIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IContractSubPipeLine>>(`${this.basePath}/api/meta/contractSubPipeLine/${encodeURIComponent(String(contractPipeLineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchKeyword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaCounterPartyGet(searchKeyword?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ICounterParty>>;
    public apiMetaCounterPartyGet(searchKeyword?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ICounterParty>>>;
    public apiMetaCounterPartyGet(searchKeyword?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ICounterParty>>>;
    public apiMetaCounterPartyGet(searchKeyword?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchKeyword !== undefined) {
            queryParameters = queryParameters.set('searchKeyword', <any>searchKeyword);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ICounterParty>>(`${this.basePath}/api/meta/counterParty`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaCounterPartyPost(command?: AddCounterPartyNameCommand, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiMetaCounterPartyPost(command?: AddCounterPartyNameCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiMetaCounterPartyPost(command?: AddCounterPartyNameCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiMetaCounterPartyPost(command?: AddCounterPartyNameCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/meta/counterParty`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns counter parties that are associated with at least one contract.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaCounterPartyUsedGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ICounterParty>>;
    public apiMetaCounterPartyUsedGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ICounterParty>>>;
    public apiMetaCounterPartyUsedGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ICounterParty>>>;
    public apiMetaCounterPartyUsedGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ICounterParty>>(`${this.basePath}/api/meta/counterParty/used`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaEntitylocalGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IEntityLocal>>;
    public apiMetaEntitylocalGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IEntityLocal>>>;
    public apiMetaEntitylocalGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IEntityLocal>>>;
    public apiMetaEntitylocalGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IEntityLocal>>(`${this.basePath}/api/meta/entitylocal`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaEnumGroupsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IEnumGroup>>;
    public apiMetaEnumGroupsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IEnumGroup>>>;
    public apiMetaEnumGroupsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IEnumGroup>>>;
    public apiMetaEnumGroupsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IEnumGroup>>(`${this.basePath}/api/meta/enumGroups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This returns a dictionary of [ValidEnum group keys, list of ValidEnum].   Example valid enum: {\&quot;$id\&quot;:\&quot;219330\&quot;,\&quot;groupKey\&quot;:\&quot;CJurisdict\&quot;,\&quot;position\&quot;:6,\&quot;enumId\&quot;:4044,\&quot;code\&quot;:\&quot;(None)\&quot;,\&quot;title\&quot;:\&quot;AU\&quot;,\&quot;describe\&quot;:\&quot;AU\&quot;,\&quot;isActive\&quot;:true}
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaGet(observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<ITaxEnum>; }>;
    public apiMetaGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<ITaxEnum>; }>>;
    public apiMetaGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<ITaxEnum>; }>>;
    public apiMetaGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: Array<ITaxEnum>; }>(`${this.basePath}/api/meta`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaImpactLocationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IImpactLocationEnumView>>;
    public apiMetaImpactLocationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IImpactLocationEnumView>>>;
    public apiMetaImpactLocationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IImpactLocationEnumView>>>;
    public apiMetaImpactLocationGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IImpactLocationEnumView>>(`${this.basePath}/api/meta/impactLocation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMetaSubpipelineGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IContractSubPipeLine>>;
    public apiMetaSubpipelineGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IContractSubPipeLine>>>;
    public apiMetaSubpipelineGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IContractSubPipeLine>>>;
    public apiMetaSubpipelineGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IContractSubPipeLine>>(`${this.basePath}/api/meta/subpipeline`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
