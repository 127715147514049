import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TechAdminComponent } from './pages/tech-admin/tech-admin.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UiModule } from './ui/ui.module';
import { RouterModule } from '@angular/router';
import { WorkflowComponent } from './pages/workflow/workflow.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { MiscComponent } from './pages/misc/misc.component';
import { MetaComponent } from './pages/meta/meta.component';
import { ApiModule } from './proxy/api.module';
import { Configuration, BASE_PATH } from './proxy';
import { ConfigurationParameters } from 'configuration';
import { HttpModule } from '@angular/http';
import { environment } from 'src/environments/environment';
import { AcctWorkflowComponent } from './pages/acct-workflow/acct-workflow.component';

// IMPORTANT: this is to replace build time enviornment variable
// with runtime enviornment by configuration service
declare var $tax: any;
export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    withCredentials: true
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    TechAdminComponent,
    HomeComponent,
    WorkflowComponent,
    NotificationComponent,
    MiscComponent,
    MetaComponent,
    AcctWorkflowComponent
  ],
  imports: [
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    UiModule,
    HttpModule
  ],
  providers: [
    { provide: BASE_PATH, useValue: $tax.taxcatapi || environment.TAXCAT_API_BASE_PATH },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

