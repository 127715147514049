import { TechAdminComponent } from './pages/tech-admin/tech-admin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { WorkflowComponent } from './pages/workflow/workflow.component';
import { MiscComponent } from './pages/misc/misc.component';
import { AcctWorkflowComponent } from './pages/acct-workflow/acct-workflow.component';

const routes: Routes = [
  {
    path: 'techadmin',
    component: TechAdminComponent
  },
  {
    path: 'notification',
    component: NotificationComponent
  },
  {
    path: 'workflow',
    component: WorkflowComponent
  }, {
    path: 'acct-workflow',
    component: AcctWorkflowComponent
  },
  {
    path: 'misc',
    component: MiscComponent
  },
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

