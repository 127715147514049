import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

declare var Mustache: any;
// declare var login: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  templateKeys = [];
  templateVersionMap = new Map(); // stores version as key and template as value for a given templateKey
  templateVersions = []; // stores all template versions for a given templateKey
  selectedVersion = 1; // current selected template version
  statusindicator = '';
  templateKeyId = '';

  tabs = {
    PREVIEW_TEMPLATE: '1',
    UPDATE_TEMPLATE: '2',
    ADD_TEMPLATE: '3',
  };

  currentTab = this.tabs.PREVIEW_TEMPLATE; // Preview by default
  // Preview
  subjectTemplate = '';
  bodyTemplate = '';
  templateString = '';
  compiledHtml = '';
  modelJsonString = '';

  // Update
  updateSubjectTemplate = '';
  updateBodyTemplate = '';

  // Add
  addSubjectTemplate = '';
  addBodyTemplate = '';
  addSendToTemplate = '';
  addCCTemplate = '';
  addBCCTemplate = '';
  addTemplateKey = '';

  constructor(private http: HttpClient) {
    console.log('constructor');
  }

  ngOnInit() {
    console.log('init');
    this.invokeGetTemplateKeys();
    console.log(this.templateKeys);
  }

  onTemplateKeyChanged(templateKeyId: any) {
    this.templateKeyId = templateKeyId;
    this.clearAllfields();
    this.invokeGetTemplate();
  }

  onTemplateVersionChanged(version: any) {
    this.selectedVersion = Number(version);
    const template = this.templateVersionMap.get(this.selectedVersion);
    if (this.currentTab === this.tabs.PREVIEW_TEMPLATE) {
      this.subjectTemplate = template.SubjectTemplate;
      this.bodyTemplate = template.BodyTemplate;
    }
    this.templateString = 'Subject: ' + this.subjectTemplate + '\n\nBody: ' + this.bodyTemplate ;
  }


  clearAllfields() {
    this.subjectTemplate = '';
    this.bodyTemplate = '';
    this.subjectTemplate = '';
    this.bodyTemplate = '';
    this.templateString = '';
    this.compiledHtml = '';
    this.modelJsonString = '';
    this.updateSubjectTemplate = '';
    this.updateBodyTemplate = '';
    this.addSubjectTemplate = '';
    this.addBodyTemplate = '';
    this.addSendToTemplate = '';
    this.addCCTemplate = '';
    this.addBCCTemplate = '';
    this.addTemplateKey = '';
    this.templateVersionMap = new Map();
    this.selectedVersion = 1;
    this.templateVersions = [];
  }

  invokeGetTemplateKeys() {
    this.http.get('/api/templateKeys').subscribe(results => {
      console.log(results);
      console.log(typeof results);
      const templateKeys = results['Payload'];
      const templateKeysJson  = JSON.parse(templateKeys);
      templateKeysJson.forEach(element => {
        let key = element.TemplateKey;
        console.log(key);
        this.templateKeys.push({
          name: key,
          value: key
        });
      });
    });
  }


  invokeGetTemplate() {
    const templateKey = this.templateKeyId;
    this.http.get('/api/template/' + templateKey)
             .subscribe(result =>  {
                console.log(result);
                let template = result['Payload'];
                let templateJson = JSON.parse(template);
                console.log(templateJson);
                templateJson.forEach(element => {
                  console.log(element.Version);
                  this.templateVersionMap.set(element.Version, element);
              });
      this.templateVersions = Array.from(this.templateVersionMap.keys());

      if (this.currentTab === this.tabs.PREVIEW_TEMPLATE) {
        const templateValue = this.templateVersionMap.get(this.selectedVersion);
        this.subjectTemplate = templateValue.SubjectTemplate;
        this.bodyTemplate = templateValue.BodyTemplate;
      } else if (this.currentTab === this.tabs.UPDATE_TEMPLATE) {
        const latestVersion = Math.max(...this.templateVersions);
        const templateValue = this.templateVersionMap.get(latestVersion);
        this.updateSubjectTemplate = templateValue.SubjectTemplate;
        this.updateBodyTemplate = templateValue.BodyTemplate;
      }

    });
    this.templateString = 'Subject: ' + this.subjectTemplate + '\n\nBody: ' + this.bodyTemplate ;

  }

  onPreviewClicked() {
    console.log(this.modelJsonString);
    console.log(this.templateString);
    this.compiledHtml = Mustache.render(this.subjectTemplate, JSON.parse(this.modelJsonString));
    this.compiledHtml += Mustache.render(this.bodyTemplate, JSON.parse(this.modelJsonString));
    console.log(this.compiledHtml);
  }

  onAddClicked(addForm: NgForm) {
    console.log('adding...');
    console.log(addForm.value);
    if (!this.addSubjectTemplate && !this.addBodyTemplate) {
      console.log('subject and body templates are required');
      alert('subject and body templates are required');
      return;
    }
    this.http.post('/api/insertTemplate', {
      templateKey: this.addTemplateKey,
      subjectTemplate: this.addSubjectTemplate,
      bodyTemplate: this.addBodyTemplate,
      sendToTemplate: this.addSendToTemplate,
      ccTemplate: this.addCCTemplate,
      bccTemplate: this.addBCCTemplate,
      createdBy: 'vinpooja' // todo -get user id
    }).subscribe(res => {
      console.log(res);
      this.statusindicator = 'Added the template to the db';
    }, err => {
      console.error(err);
      this.statusindicator = 'Error';
      this.templateKeyId = '';
      this.clearAllfields();
    });
  }

  onUpdateClicked(updateForm: NgForm) {
    console.log('updating...');
    console.log(updateForm.value);
    if (!(this.updateSubjectTemplate || this.updateBodyTemplate)) {
      console.log('at least one input is required(subject or body template)');
      alert('At least one input is required(subject or body template)');
      return;
    }
    this.http.put('/api/updateTemplate', {
      templateKey: this.templateKeyId,
      subjectTemplate: this.updateSubjectTemplate,
      bodyTemplate: this.updateBodyTemplate,
      lastModifiedBy: 'vinpooja' // todo -get user id
    }).subscribe(res => {
        console.log(res);
        this.statusindicator = 'Updated the database!';
        this.templateKeyId = '';
        this.clearAllfields();
      }, err => {
        console.error(err);
        this.statusindicator = 'Error';
      });
  }

  onTabChanged(event: any) {
    console.log('tab changed...');
    console.log('resetting values...');
    console.log(event.detail);
    const selectedTab = JSON.parse(JSON.stringify(event.detail));
    this.currentTab = selectedTab['selected-tab-id'];
    this.templateKeyId = '';
    this.statusindicator = '';
    this.clearAllfields();

  }

}
