/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { TaxDiscussionForumDocument } from '../model/taxDiscussionForumDocument';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FileService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param docId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileByDocIdDelete(docId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiFileByDocIdDelete(docId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiFileByDocIdDelete(docId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiFileByDocIdDelete(docId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (docId === null || docId === undefined) {
            throw new Error('Required parameter docId was null or undefined when calling apiFileByDocIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/api/file/${encodeURIComponent(String(docId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param docId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileByDocIdGet(docId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiFileByDocIdGet(docId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiFileByDocIdGet(docId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiFileByDocIdGet(docId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (docId === null || docId === undefined) {
            throw new Error('Required parameter docId was null or undefined when calling apiFileByDocIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/file/${encodeURIComponent(String(docId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes the type of a contract document.
     * 
     * @param docId 
     * @param newDocumentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileChangeTypePatch(docId: number, newDocumentType: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiFileChangeTypePatch(docId: number, newDocumentType: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiFileChangeTypePatch(docId: number, newDocumentType: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiFileChangeTypePatch(docId: number, newDocumentType: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (docId === null || docId === undefined) {
            throw new Error('Required parameter docId was null or undefined when calling apiFileChangeTypePatch.');
        }
        if (newDocumentType === null || newDocumentType === undefined) {
            throw new Error('Required parameter newDocumentType was null or undefined when calling apiFileChangeTypePatch.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (docId !== undefined) {
            queryParameters = queryParameters.set('DocId', <any>docId);
        }
        if (newDocumentType !== undefined) {
            queryParameters = queryParameters.set('NewDocumentType', <any>newDocumentType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.patch<boolean>(`${this.basePath}/api/file/changeType`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param docId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileForumByDocIdDelete(docId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiFileForumByDocIdDelete(docId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiFileForumByDocIdDelete(docId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiFileForumByDocIdDelete(docId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (docId === null || docId === undefined) {
            throw new Error('Required parameter docId was null or undefined when calling apiFileForumByDocIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/api/file/forum/${encodeURIComponent(String(docId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param docId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileForumByDocIdGet(docId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiFileForumByDocIdGet(docId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiFileForumByDocIdGet(docId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiFileForumByDocIdGet(docId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (docId === null || docId === undefined) {
            throw new Error('Required parameter docId was null or undefined when calling apiFileForumByDocIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/file/forum/${encodeURIComponent(String(docId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param files 
     * @param contractKeyId 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFileForumPost(files?: string, contractKeyId?: string, role?: string, observe?: 'body', reportProgress?: boolean): Observable<TaxDiscussionForumDocument>;
    public apiFileForumPost(files?: string, contractKeyId?: string, role?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TaxDiscussionForumDocument>>;
    public apiFileForumPost(files?: string, contractKeyId?: string, role?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TaxDiscussionForumDocument>>;
    public apiFileForumPost(files?: string, contractKeyId?: string, role?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (files !== undefined) {
            queryParameters = queryParameters.set('files', <any>files);
        }
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('contractKeyId', <any>contractKeyId);
        }
        if (role !== undefined) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<TaxDiscussionForumDocument>(`${this.basePath}/api/file/forum`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param documentType 
     * @param role 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiFilePost(contractKeyId?: string, documentType?: string, role?: string, files?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiFilePost(contractKeyId?: string, documentType?: string, role?: string, files?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiFilePost(contractKeyId?: string, documentType?: string, role?: string, files?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiFilePost(contractKeyId?: string, documentType?: string, role?: string, files?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('contractKeyId', <any>contractKeyId);
        }
        if (documentType !== undefined) {
            queryParameters = queryParameters.set('documentType', <any>documentType);
        }
        if (role !== undefined) {
            queryParameters = queryParameters.set('role', <any>role);
        }
        if (files !== undefined) {
            queryParameters = queryParameters.set('files', <any>files);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/file`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
