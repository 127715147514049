/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AssignPointOfContractForInitialAdminReviewCommand } from '../model/assignPointOfContractForInitialAdminReviewCommand';
import { CancelContractCommand } from '../model/cancelContractCommand';
import { ConfirmInvolvedEntitiesCommand } from '../model/confirmInvolvedEntitiesCommand';
import { IAdminContractOverview } from '../model/iAdminContractOverview';
import { IContractFunctionalArea } from '../model/iContractFunctionalArea';
import { IObjectRoleUserMap } from '../model/iObjectRoleUserMap';
import { ReSubmitForInternalApprovalCommand } from '../model/reSubmitForInternalApprovalCommand';
import { RemoveFunctionalAreaForEntityConfirmationCommand } from '../model/removeFunctionalAreaForEntityConfirmationCommand';
import { SubmitForFinalApprovalCommand } from '../model/submitForFinalApprovalCommand';
import { SubmitForInternalApprovalCommand } from '../model/submitForInternalApprovalCommand';
import { UpdateAdminContractCommand } from '../model/updateAdminContractCommand';
import { UpdateAdminEntityConfirmationContractCommand } from '../model/updateAdminEntityConfirmationContractCommand';
import { UpdateContractFunctionalAreaApproverCommand } from '../model/updateContractFunctionalAreaApproverCommand';
import { UpdateInvolvedEntitiesCommand } from '../model/updateInvolvedEntitiesCommand';
import { UpdateRequestCommentCommand } from '../model/updateRequestCommentCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminContractService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractAdminCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IObjectRoleUserMap>>;
    public apiAdminContractAdminCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IObjectRoleUserMap>>>;
    public apiAdminContractAdminCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IObjectRoleUserMap>>>;
    public apiAdminContractAdminCarbonCopyByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAdminContractAdminCarbonCopyByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IObjectRoleUserMap>>(`${this.basePath}/api/admin/contract/adminCarbonCopy/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param alias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractAdminCarbonCopyDelete(contractKeyId?: string, alias?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractAdminCarbonCopyDelete(contractKeyId?: string, alias?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractAdminCarbonCopyDelete(contractKeyId?: string, alias?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractAdminCarbonCopyDelete(contractKeyId?: string, alias?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('ContractKeyId', <any>contractKeyId);
        }
        if (alias !== undefined) {
            queryParameters = queryParameters.set('Alias', <any>alias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/api/admin/contract/adminCarbonCopy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param alias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractAdminCarbonCopyPost(contractKeyId?: string, alias?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractAdminCarbonCopyPost(contractKeyId?: string, alias?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractAdminCarbonCopyPost(contractKeyId?: string, alias?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractAdminCarbonCopyPost(contractKeyId?: string, alias?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('ContractKeyId', <any>contractKeyId);
        }
        if (alias !== undefined) {
            queryParameters = queryParameters.set('Alias', <any>alias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/admin/contract/adminCarbonCopy`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractAssignPointOfContactForInitialAdminReviewPut(command?: AssignPointOfContractForInitialAdminReviewCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractAssignPointOfContactForInitialAdminReviewPut(command?: AssignPointOfContractForInitialAdminReviewCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractAssignPointOfContactForInitialAdminReviewPut(command?: AssignPointOfContractForInitialAdminReviewCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractAssignPointOfContactForInitialAdminReviewPut(command?: AssignPointOfContractForInitialAdminReviewCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/assignPointOfContactForInitialAdminReview`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractByContractKeyIdFunctionalAreasGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IContractFunctionalArea>>;
    public apiAdminContractByContractKeyIdFunctionalAreasGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IContractFunctionalArea>>>;
    public apiAdminContractByContractKeyIdFunctionalAreasGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IContractFunctionalArea>>>;
    public apiAdminContractByContractKeyIdFunctionalAreasGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAdminContractByContractKeyIdFunctionalAreasGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IContractFunctionalArea>>(`${this.basePath}/api/admin/contract/${encodeURIComponent(String(contractKeyId))}/functionalAreas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the submission contract overview. for submitter and submitter cc
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiAdminContractByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiAdminContractByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiAdminContractByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAdminContractByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IAdminContractOverview>(`${this.basePath}/api/admin/contract/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Admin can cancel contracts.
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractDelete(command?: CancelContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractDelete(command?: CancelContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractDelete(command?: CancelContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractDelete(command?: CancelContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/admin/contract`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractEntityConfirmationContractApproversPut(command?: UpdateContractFunctionalAreaApproverCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractEntityConfirmationContractApproversPut(command?: UpdateContractFunctionalAreaApproverCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractEntityConfirmationContractApproversPut(command?: UpdateContractFunctionalAreaApproverCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractEntityConfirmationContractApproversPut(command?: UpdateContractFunctionalAreaApproverCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/entityConfirmationContract/approvers`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an admin to confirm the Involved Entities for a contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractEntityConfirmationContractConfirmPut(command?: ConfirmInvolvedEntitiesCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractEntityConfirmationContractConfirmPut(command?: ConfirmInvolvedEntitiesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractEntityConfirmationContractConfirmPut(command?: ConfirmInvolvedEntitiesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractEntityConfirmationContractConfirmPut(command?: ConfirmInvolvedEntitiesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/entityConfirmationContract/confirm`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractEntityConfirmationContractFunctionalAreaDelete(command?: RemoveFunctionalAreaForEntityConfirmationCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractEntityConfirmationContractFunctionalAreaDelete(command?: RemoveFunctionalAreaForEntityConfirmationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractEntityConfirmationContractFunctionalAreaDelete(command?: RemoveFunctionalAreaForEntityConfirmationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractEntityConfirmationContractFunctionalAreaDelete(command?: RemoveFunctionalAreaForEntityConfirmationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/admin/contract/entityConfirmationContract/functionalArea`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows admin to update an entity confirmation contract
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractEntityConfirmationContractPatch(command?: UpdateAdminEntityConfirmationContractCommand, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiAdminContractEntityConfirmationContractPatch(command?: UpdateAdminEntityConfirmationContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiAdminContractEntityConfirmationContractPatch(command?: UpdateAdminEntityConfirmationContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiAdminContractEntityConfirmationContractPatch(command?: UpdateAdminEntityConfirmationContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<IAdminContractOverview>(`${this.basePath}/api/admin/contract/entityConfirmationContract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an admin to update the Involved Entities for a contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractInvolvedEntitiesPatch(command?: UpdateInvolvedEntitiesCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractInvolvedEntitiesPatch(command?: UpdateInvolvedEntitiesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractInvolvedEntitiesPatch(command?: UpdateInvolvedEntitiesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractInvolvedEntitiesPatch(command?: UpdateInvolvedEntitiesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/admin/contract/involvedEntities`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows admin to update a contract
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractPatch(command?: UpdateAdminContractCommand, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiAdminContractPatch(command?: UpdateAdminContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiAdminContractPatch(command?: UpdateAdminContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiAdminContractPatch(command?: UpdateAdminContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<IAdminContractOverview>(`${this.basePath}/api/admin/contract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to resubmit the contract to approvers.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/reSubmitForInternalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pipelineId 
     * @param reclassifiedPipelineId 
     * @param contractKeyId 
     * @param submitterAlias 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractReclassifyPut(pipelineId: number, reclassifiedPipelineId: number, contractKeyId?: string, submitterAlias?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractReclassifyPut(pipelineId: number, reclassifiedPipelineId: number, contractKeyId?: string, submitterAlias?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractReclassifyPut(pipelineId: number, reclassifiedPipelineId: number, contractKeyId?: string, submitterAlias?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractReclassifyPut(pipelineId: number, reclassifiedPipelineId: number, contractKeyId?: string, submitterAlias?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (pipelineId === null || pipelineId === undefined) {
            throw new Error('Required parameter pipelineId was null or undefined when calling apiAdminContractReclassifyPut.');
        }
        if (reclassifiedPipelineId === null || reclassifiedPipelineId === undefined) {
            throw new Error('Required parameter reclassifiedPipelineId was null or undefined when calling apiAdminContractReclassifyPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('ContractKeyId', <any>contractKeyId);
        }
        if (pipelineId !== undefined) {
            queryParameters = queryParameters.set('PipelineId', <any>pipelineId);
        }
        if (reclassifiedPipelineId !== undefined) {
            queryParameters = queryParameters.set('ReclassifiedPipelineId', <any>reclassifiedPipelineId);
        }
        if (submitterAlias !== undefined) {
            queryParameters = queryParameters.set('SubmitterAlias', <any>submitterAlias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/reclassify`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an admin to update the RequestComment field.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/admin/contract/requestComment`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to submit the contract for final approval.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/submitForFinalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to submit the contract to approvers.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAdminContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAdminContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAdminContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAdminContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/admin/contract/submitForInternalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
