/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { IAcctAdminWorklistItem } from '../model/iAcctAdminWorklistItem';
import { IAcctApproverWorklistItem } from '../model/iAcctApproverWorklistItem';
import { IAdminWorklistItem } from '../model/iAdminWorklistItem';
import { IApproverWorklistItem } from '../model/iApproverWorklistItem';
import { IFinalApproverWorklistItem } from '../model/iFinalApproverWorklistItem';
import { IImpactAreaWorklistItem } from '../model/iImpactAreaWorklistItem';
import { ISubmitterWorklistItem } from '../model/iSubmitterWorklistItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorklistService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param statusIdList 
     * @param statusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAcctadminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IAcctAdminWorklistItem>>;
    public apiWorklistAcctadminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAcctAdminWorklistItem>>>;
    public apiWorklistAcctadminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAcctAdminWorklistItem>>>;
    public apiWorklistAcctadminGet(statusIdList?: Array<number>, statusGroup?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusIdList) {
            statusIdList.forEach((element) => {
                queryParameters = queryParameters.append('statusIdList', <any>element);
            })
        }
        if (statusGroup !== undefined) {
            queryParameters = queryParameters.set('statusGroup', <any>statusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAcctAdminWorklistItem>>(`${this.basePath}/api/worklist/acctadmin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reviewerPending 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAcctapproverGet(reviewerPending: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IAcctApproverWorklistItem>>;
    public apiWorklistAcctapproverGet(reviewerPending: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAcctApproverWorklistItem>>>;
    public apiWorklistAcctapproverGet(reviewerPending: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAcctApproverWorklistItem>>>;
    public apiWorklistAcctapproverGet(reviewerPending: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reviewerPending === null || reviewerPending === undefined) {
            throw new Error('Required parameter reviewerPending was null or undefined when calling apiWorklistAcctapproverGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reviewerPending !== undefined) {
            queryParameters = queryParameters.set('reviewerPending', <any>reviewerPending);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAcctApproverWorklistItem>>(`${this.basePath}/api/worklist/acctapprover`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAdminCarbonCopyGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IAdminWorklistItem>>;
    public apiWorklistAdminCarbonCopyGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminCarbonCopyGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminCarbonCopyGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAdminWorklistItem>>(`${this.basePath}/api/worklist/adminCarbonCopy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param statusIdList 
     * @param statusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAdminEntityConfirmationGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IAdminWorklistItem>>;
    public apiWorklistAdminEntityConfirmationGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminEntityConfirmationGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminEntityConfirmationGet(statusIdList?: Array<number>, statusGroup?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusIdList) {
            statusIdList.forEach((element) => {
                queryParameters = queryParameters.append('statusIdList', <any>element);
            })
        }
        if (statusGroup !== undefined) {
            queryParameters = queryParameters.set('statusGroup', <any>statusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAdminWorklistItem>>(`${this.basePath}/api/worklist/admin/entityConfirmation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param request 
     * @param statusIdList 
     * @param statusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAdminEntityConfirmationKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorklistAdminEntityConfirmationKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorklistAdminEntityConfirmationKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorklistAdminEntityConfirmationKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined) {
            queryParameters = queryParameters.set('request', <any>request);
        }
        if (statusIdList) {
            statusIdList.forEach((element) => {
                queryParameters = queryParameters.append('statusIdList', <any>element);
            })
        }
        if (statusGroup !== undefined) {
            queryParameters = queryParameters.set('statusGroup', <any>statusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/worklist/admin/entityConfirmation/kendo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param statusIdList 
     * @param statusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAdminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IAdminWorklistItem>>;
    public apiWorklistAdminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminGet(statusIdList?: Array<number>, statusGroup?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAdminWorklistItem>>>;
    public apiWorklistAdminGet(statusIdList?: Array<number>, statusGroup?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (statusIdList) {
            statusIdList.forEach((element) => {
                queryParameters = queryParameters.append('statusIdList', <any>element);
            })
        }
        if (statusGroup !== undefined) {
            queryParameters = queryParameters.set('statusGroup', <any>statusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAdminWorklistItem>>(`${this.basePath}/api/worklist/admin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param request 
     * @param statusIdList 
     * @param statusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistAdminKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorklistAdminKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorklistAdminKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorklistAdminKendoGet(request?: string, statusIdList?: Array<number>, statusGroup?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined) {
            queryParameters = queryParameters.set('request', <any>request);
        }
        if (statusIdList) {
            statusIdList.forEach((element) => {
                queryParameters = queryParameters.append('statusIdList', <any>element);
            })
        }
        if (statusGroup !== undefined) {
            queryParameters = queryParameters.set('statusGroup', <any>statusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/worklist/admin/kendo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reviewerPending 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistApproverGet(reviewerPending: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IApproverWorklistItem>>;
    public apiWorklistApproverGet(reviewerPending: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IApproverWorklistItem>>>;
    public apiWorklistApproverGet(reviewerPending: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IApproverWorklistItem>>>;
    public apiWorklistApproverGet(reviewerPending: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reviewerPending === null || reviewerPending === undefined) {
            throw new Error('Required parameter reviewerPending was null or undefined when calling apiWorklistApproverGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reviewerPending !== undefined) {
            queryParameters = queryParameters.set('reviewerPending', <any>reviewerPending);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IApproverWorklistItem>>(`${this.basePath}/api/worklist/approver`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reviewerPending 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistApproverKendoGet(reviewerPending: number, request?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorklistApproverKendoGet(reviewerPending: number, request?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorklistApproverKendoGet(reviewerPending: number, request?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorklistApproverKendoGet(reviewerPending: number, request?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reviewerPending === null || reviewerPending === undefined) {
            throw new Error('Required parameter reviewerPending was null or undefined when calling apiWorklistApproverKendoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined) {
            queryParameters = queryParameters.set('request', <any>request);
        }
        if (reviewerPending !== undefined) {
            queryParameters = queryParameters.set('reviewerPending', <any>reviewerPending);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/worklist/approver/kendo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistFinalApproverGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IFinalApproverWorklistItem>>;
    public apiWorklistFinalApproverGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IFinalApproverWorklistItem>>>;
    public apiWorklistFinalApproverGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IFinalApproverWorklistItem>>>;
    public apiWorklistFinalApproverGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IFinalApproverWorklistItem>>(`${this.basePath}/api/worklist/finalApprover`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistImpactareaGet(status: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IImpactAreaWorklistItem>>;
    public apiWorklistImpactareaGet(status: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IImpactAreaWorklistItem>>>;
    public apiWorklistImpactareaGet(status: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IImpactAreaWorklistItem>>>;
    public apiWorklistImpactareaGet(status: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling apiWorklistImpactareaGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IImpactAreaWorklistItem>>(`${this.basePath}/api/worklist/impactarea`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param constractStatusGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistSubmitterGet(constractStatusGroup: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ISubmitterWorklistItem>>;
    public apiWorklistSubmitterGet(constractStatusGroup: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ISubmitterWorklistItem>>>;
    public apiWorklistSubmitterGet(constractStatusGroup: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ISubmitterWorklistItem>>>;
    public apiWorklistSubmitterGet(constractStatusGroup: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (constractStatusGroup === null || constractStatusGroup === undefined) {
            throw new Error('Required parameter constractStatusGroup was null or undefined when calling apiWorklistSubmitterGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (constractStatusGroup !== undefined) {
            queryParameters = queryParameters.set('constractStatusGroup', <any>constractStatusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ISubmitterWorklistItem>>(`${this.basePath}/api/worklist/submitter`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param constractStatusGroup 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistSubmitterKendoGet(constractStatusGroup: number, request?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorklistSubmitterKendoGet(constractStatusGroup: number, request?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorklistSubmitterKendoGet(constractStatusGroup: number, request?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorklistSubmitterKendoGet(constractStatusGroup: number, request?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (constractStatusGroup === null || constractStatusGroup === undefined) {
            throw new Error('Required parameter constractStatusGroup was null or undefined when calling apiWorklistSubmitterKendoGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined) {
            queryParameters = queryParameters.set('request', <any>request);
        }
        if (constractStatusGroup !== undefined) {
            queryParameters = queryParameters.set('constractStatusGroup', <any>constractStatusGroup);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/worklist/submitter/kendo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorklistUniversalAdminGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IAdminWorklistItem>>;
    public apiWorklistUniversalAdminGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IAdminWorklistItem>>>;
    public apiWorklistUniversalAdminGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IAdminWorklistItem>>>;
    public apiWorklistUniversalAdminGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IAdminWorklistItem>>(`${this.basePath}/api/worklist/universalAdmin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
