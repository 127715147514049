/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ICounterPartyServices {
    counterPartyServiceEnumId?: ICounterPartyServices.CounterPartyServiceEnumIdEnum;
}
export namespace ICounterPartyServices {
    export type CounterPartyServiceEnumIdEnum = 4008 | 4009 | 4010 | 4011 | 4012 | 4013 | 4014 | 4015;
    export const CounterPartyServiceEnumIdEnum = {
        NUMBER_4008: 4008 as CounterPartyServiceEnumIdEnum,
        NUMBER_4009: 4009 as CounterPartyServiceEnumIdEnum,
        NUMBER_4010: 4010 as CounterPartyServiceEnumIdEnum,
        NUMBER_4011: 4011 as CounterPartyServiceEnumIdEnum,
        NUMBER_4012: 4012 as CounterPartyServiceEnumIdEnum,
        NUMBER_4013: 4013 as CounterPartyServiceEnumIdEnum,
        NUMBER_4014: 4014 as CounterPartyServiceEnumIdEnum,
        NUMBER_4015: 4015 as CounterPartyServiceEnumIdEnum
    }
}
