/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AddFunctionAreaCommand } from '../model/addFunctionAreaCommand';
import { AddImpactAreaCommand } from '../model/addImpactAreaCommand';
import { AddImpactAreaOwnersCommand } from '../model/addImpactAreaOwnersCommand';
import { AddJurisdictionBusinessLineFunctionalAreaApproverCommand } from '../model/addJurisdictionBusinessLineFunctionalAreaApproverCommand';
import { AddMembersToFunctionAreaCommand } from '../model/addMembersToFunctionAreaCommand';
import { AddNewQuestionCommand } from '../model/addNewQuestionCommand';
import { AddOptionGroupOptionCommand } from '../model/addOptionGroupOptionCommand';
import { AddQuestionConditionCommand } from '../model/addQuestionConditionCommand';
import { AddQuestionToSubPipelineCommand } from '../model/addQuestionToSubPipelineCommand';
import { ContractType } from '../model/contractType';
import { DeactivateJurisdictionBusinessLineCommand } from '../model/deactivateJurisdictionBusinessLineCommand';
import { DeactivateJurisdictionCommand } from '../model/deactivateJurisdictionCommand';
import { EntityLocalViewModel } from '../model/entityLocalViewModel';
import { IANTUser } from '../model/iANTUser';
import { IApproverRuleThresholds } from '../model/iApproverRuleThresholds';
import { ICatFunctionAreaApprovers } from '../model/iCatFunctionAreaApprovers';
import { IContractTypeDetail } from '../model/iContractTypeDetail';
import { IEntityLocalDetail } from '../model/iEntityLocalDetail';
import { IFuncAreaApproverJurisdiction } from '../model/iFuncAreaApproverJurisdiction';
import { IFuncAreaApproverJurisdictionBusinessLine } from '../model/iFuncAreaApproverJurisdictionBusinessLine';
import { IQuestion } from '../model/iQuestion';
import { IQuestionType } from '../model/iQuestionType';
import { NewContractType } from '../model/newContractType';
import { NewEntityLocal } from '../model/newEntityLocal';
import { ReactivateJurisdictionBusinessLineCommand } from '../model/reactivateJurisdictionBusinessLineCommand';
import { ReactivateJurisdictionCommand } from '../model/reactivateJurisdictionCommand';
import { RemoveFunctionAreaCommand } from '../model/removeFunctionAreaCommand';
import { RemoveImpactAreaCommand } from '../model/removeImpactAreaCommand';
import { RemoveImpactAreaOwnersCommand } from '../model/removeImpactAreaOwnersCommand';
import { RemoveJurisdictionBusinessLineFunctionalAreaApproverCommand } from '../model/removeJurisdictionBusinessLineFunctionalAreaApproverCommand';
import { RemoveMembersToFunctionAreaCommand } from '../model/removeMembersToFunctionAreaCommand';
import { RemoveOptionGroupOptionCommand } from '../model/removeOptionGroupOptionCommand';
import { TotalResult } from '../model/totalResult';
import { UpdateApproverRuleThresholdCommand } from '../model/updateApproverRuleThresholdCommand';
import { UpdateQuestionCommand } from '../model/updateQuestionCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ManagementService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementApproverRulesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IApproverRuleThresholds>>;
    public apiManagementApproverRulesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IApproverRuleThresholds>>>;
    public apiManagementApproverRulesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IApproverRuleThresholds>>>;
    public apiManagementApproverRulesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IApproverRuleThresholds>>(`${this.basePath}/api/management/approver-rules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementApproverRulesPost(command?: UpdateApproverRuleThresholdCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementApproverRulesPost(command?: UpdateApproverRuleThresholdCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementApproverRulesPost(command?: UpdateApproverRuleThresholdCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementApproverRulesPost(command?: UpdateApproverRuleThresholdCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/management/approver-rules`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param activeOnly 
     * @param enumId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementContractTypeGet(activeOnly: boolean, enumId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IContractTypeDetail>>;
    public apiManagementContractTypeGet(activeOnly: boolean, enumId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IContractTypeDetail>>>;
    public apiManagementContractTypeGet(activeOnly: boolean, enumId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IContractTypeDetail>>>;
    public apiManagementContractTypeGet(activeOnly: boolean, enumId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling apiManagementContractTypeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (enumId !== undefined) {
            queryParameters = queryParameters.set('enumId', <any>enumId);
        }
        if (activeOnly !== undefined) {
            queryParameters = queryParameters.set('activeOnly', <any>activeOnly);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IContractTypeDetail>>(`${this.basePath}/api/management/contractType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementContractTypePatch(contractType?: ContractType, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementContractTypePatch(contractType?: ContractType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementContractTypePatch(contractType?: ContractType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementContractTypePatch(contractType?: ContractType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/management/contractType`,
            contractType,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementContractTypePost(contractType?: NewContractType, observe?: 'body', reportProgress?: boolean): Observable<IContractTypeDetail>;
    public apiManagementContractTypePost(contractType?: NewContractType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IContractTypeDetail>>;
    public apiManagementContractTypePost(contractType?: NewContractType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IContractTypeDetail>>;
    public apiManagementContractTypePost(contractType?: NewContractType, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<IContractTypeDetail>(`${this.basePath}/api/management/contractType`,
            contractType,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param activeOnly 
     * @param entityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementEntityGet(activeOnly: boolean, entityId?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IEntityLocalDetail>>;
    public apiManagementEntityGet(activeOnly: boolean, entityId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IEntityLocalDetail>>>;
    public apiManagementEntityGet(activeOnly: boolean, entityId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IEntityLocalDetail>>>;
    public apiManagementEntityGet(activeOnly: boolean, entityId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (activeOnly === null || activeOnly === undefined) {
            throw new Error('Required parameter activeOnly was null or undefined when calling apiManagementEntityGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (entityId !== undefined) {
            queryParameters = queryParameters.set('entityId', <any>entityId);
        }
        if (activeOnly !== undefined) {
            queryParameters = queryParameters.set('activeOnly', <any>activeOnly);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IEntityLocalDetail>>(`${this.basePath}/api/management/entity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param entity 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementEntityPatch(entity?: EntityLocalViewModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementEntityPatch(entity?: EntityLocalViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementEntityPatch(entity?: EntityLocalViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementEntityPatch(entity?: EntityLocalViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/management/entity`,
            entity,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param entity 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementEntityPost(entity?: NewEntityLocal, observe?: 'body', reportProgress?: boolean): Observable<IEntityLocalDetail>;
    public apiManagementEntityPost(entity?: NewEntityLocal, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IEntityLocalDetail>>;
    public apiManagementEntityPost(entity?: NewEntityLocal, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IEntityLocalDetail>>;
    public apiManagementEntityPost(entity?: NewEntityLocal, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<IEntityLocalDetail>(`${this.basePath}/api/management/entity`,
            entity,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param functionAreaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaApproversByFunctionAreaIdGet(functionAreaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ICatFunctionAreaApprovers>>;
    public apiManagementFunctionareaApproversByFunctionAreaIdGet(functionAreaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ICatFunctionAreaApprovers>>>;
    public apiManagementFunctionareaApproversByFunctionAreaIdGet(functionAreaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ICatFunctionAreaApprovers>>>;
    public apiManagementFunctionareaApproversByFunctionAreaIdGet(functionAreaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (functionAreaId === null || functionAreaId === undefined) {
            throw new Error('Required parameter functionAreaId was null or undefined when calling apiManagementFunctionareaApproversByFunctionAreaIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ICatFunctionAreaApprovers>>(`${this.basePath}/api/management/functionarea/approvers/${encodeURIComponent(String(functionAreaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param functionAreaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaByFunctionAreaIdGet(functionAreaId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IANTUser>>;
    public apiManagementFunctionareaByFunctionAreaIdGet(functionAreaId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IANTUser>>>;
    public apiManagementFunctionareaByFunctionAreaIdGet(functionAreaId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IANTUser>>>;
    public apiManagementFunctionareaByFunctionAreaIdGet(functionAreaId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (functionAreaId === null || functionAreaId === undefined) {
            throw new Error('Required parameter functionAreaId was null or undefined when calling apiManagementFunctionareaByFunctionAreaIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IANTUser>>(`${this.basePath}/api/management/functionarea/${encodeURIComponent(String(functionAreaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaDelete(command?: RemoveFunctionAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementFunctionareaDelete(command?: RemoveFunctionAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementFunctionareaDelete(command?: RemoveFunctionAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementFunctionareaDelete(command?: RemoveFunctionAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/functionarea`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaMembersDelete(command?: RemoveMembersToFunctionAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<TotalResult>;
    public apiManagementFunctionareaMembersDelete(command?: RemoveMembersToFunctionAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TotalResult>>;
    public apiManagementFunctionareaMembersDelete(command?: RemoveMembersToFunctionAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TotalResult>>;
    public apiManagementFunctionareaMembersDelete(command?: RemoveMembersToFunctionAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<TotalResult>(`${this.basePath}/api/management/functionarea/members`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaMembersPost(command?: AddMembersToFunctionAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<TotalResult>;
    public apiManagementFunctionareaMembersPost(command?: AddMembersToFunctionAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TotalResult>>;
    public apiManagementFunctionareaMembersPost(command?: AddMembersToFunctionAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TotalResult>>;
    public apiManagementFunctionareaMembersPost(command?: AddMembersToFunctionAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<TotalResult>(`${this.basePath}/api/management/functionarea/members`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementFunctionareaPost(command?: AddFunctionAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiManagementFunctionareaPost(command?: AddFunctionAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiManagementFunctionareaPost(command?: AddFunctionAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiManagementFunctionareaPost(command?: AddFunctionAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/management/functionarea`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementImpactareaDelete(command?: RemoveImpactAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementImpactareaDelete(command?: RemoveImpactAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementImpactareaDelete(command?: RemoveImpactAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementImpactareaDelete(command?: RemoveImpactAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/impactarea`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementImpactareaOwnersDelete(command?: RemoveImpactAreaOwnersCommand, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public apiManagementImpactareaOwnersDelete(command?: RemoveImpactAreaOwnersCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public apiManagementImpactareaOwnersDelete(command?: RemoveImpactAreaOwnersCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public apiManagementImpactareaOwnersDelete(command?: RemoveImpactAreaOwnersCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<Array<number>>(`${this.basePath}/api/management/impactarea/owners`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementImpactareaOwnersPost(command?: AddImpactAreaOwnersCommand, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public apiManagementImpactareaOwnersPost(command?: AddImpactAreaOwnersCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public apiManagementImpactareaOwnersPost(command?: AddImpactAreaOwnersCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public apiManagementImpactareaOwnersPost(command?: AddImpactAreaOwnersCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Array<number>>(`${this.basePath}/api/management/impactarea/owners`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementImpactareaPost(command?: AddImpactAreaCommand, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiManagementImpactareaPost(command?: AddImpactAreaCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiManagementImpactareaPost(command?: AddImpactAreaCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiManagementImpactareaPost(command?: AddImpactAreaCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/management/impactarea`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionBusinessLineDelete(command?: DeactivateJurisdictionBusinessLineCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionBusinessLineDelete(command?: DeactivateJurisdictionBusinessLineCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionBusinessLineDelete(command?: DeactivateJurisdictionBusinessLineCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionBusinessLineDelete(command?: DeactivateJurisdictionBusinessLineCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/jurisdiction/business-line`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverDelete(command?: RemoveJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverDelete(command?: RemoveJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverDelete(command?: RemoveJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverDelete(command?: RemoveJurisdictionBusinessLineFunctionalAreaApproverCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/jurisdiction/business-line/functiona-area-approver`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverPut(command?: AddJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverPut(command?: AddJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverPut(command?: AddJurisdictionBusinessLineFunctionalAreaApproverCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionBusinessLineFunctionaAreaApproverPut(command?: AddJurisdictionBusinessLineFunctionalAreaApproverCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/jurisdiction/business-line/functiona-area-approver`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionBusinessLinePut(command?: ReactivateJurisdictionBusinessLineCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionBusinessLinePut(command?: ReactivateJurisdictionBusinessLineCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionBusinessLinePut(command?: ReactivateJurisdictionBusinessLineCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionBusinessLinePut(command?: ReactivateJurisdictionBusinessLineCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/jurisdiction/business-line`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jurisdictionId 
     * @param businessLineId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet(jurisdictionId: number, businessLineId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ICatFunctionAreaApprovers>>;
    public apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet(jurisdictionId: number, businessLineId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ICatFunctionAreaApprovers>>>;
    public apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet(jurisdictionId: number, businessLineId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ICatFunctionAreaApprovers>>>;
    public apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet(jurisdictionId: number, businessLineId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jurisdictionId === null || jurisdictionId === undefined) {
            throw new Error('Required parameter jurisdictionId was null or undefined when calling apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet.');
        }
        if (businessLineId === null || businessLineId === undefined) {
            throw new Error('Required parameter businessLineId was null or undefined when calling apiManagementJurisdictionByJurisdictionIdByBusinessLineIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<ICatFunctionAreaApprovers>>(`${this.basePath}/api/management/jurisdiction/${encodeURIComponent(String(jurisdictionId))}/${encodeURIComponent(String(businessLineId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jurisdictionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionByJurisdictionIdGet(jurisdictionId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<IFuncAreaApproverJurisdictionBusinessLine>>;
    public apiManagementJurisdictionByJurisdictionIdGet(jurisdictionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IFuncAreaApproverJurisdictionBusinessLine>>>;
    public apiManagementJurisdictionByJurisdictionIdGet(jurisdictionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IFuncAreaApproverJurisdictionBusinessLine>>>;
    public apiManagementJurisdictionByJurisdictionIdGet(jurisdictionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jurisdictionId === null || jurisdictionId === undefined) {
            throw new Error('Required parameter jurisdictionId was null or undefined when calling apiManagementJurisdictionByJurisdictionIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IFuncAreaApproverJurisdictionBusinessLine>>(`${this.basePath}/api/management/jurisdiction/${encodeURIComponent(String(jurisdictionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionDelete(command?: DeactivateJurisdictionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionDelete(command?: DeactivateJurisdictionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionDelete(command?: DeactivateJurisdictionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionDelete(command?: DeactivateJurisdictionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/jurisdiction`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IFuncAreaApproverJurisdiction>>;
    public apiManagementJurisdictionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IFuncAreaApproverJurisdiction>>>;
    public apiManagementJurisdictionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IFuncAreaApproverJurisdiction>>>;
    public apiManagementJurisdictionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IFuncAreaApproverJurisdiction>>(`${this.basePath}/api/management/jurisdiction`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementJurisdictionPut(command?: ReactivateJurisdictionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementJurisdictionPut(command?: ReactivateJurisdictionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementJurisdictionPut(command?: ReactivateJurisdictionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementJurisdictionPut(command?: ReactivateJurisdictionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/jurisdiction`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementOptionGroupsOptionDelete(command?: RemoveOptionGroupOptionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementOptionGroupsOptionDelete(command?: RemoveOptionGroupOptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementOptionGroupsOptionDelete(command?: RemoveOptionGroupOptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementOptionGroupsOptionDelete(command?: RemoveOptionGroupOptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/management/option-groups/option`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementOptionGroupsOptionPost(command?: AddOptionGroupOptionCommand, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiManagementOptionGroupsOptionPost(command?: AddOptionGroupOptionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiManagementOptionGroupsOptionPost(command?: AddOptionGroupOptionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiManagementOptionGroupsOptionPost(command?: AddOptionGroupOptionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/management/option-groups/option`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IQuestionType>>;
    public apiManagementQuestionTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IQuestionType>>>;
    public apiManagementQuestionTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IQuestionType>>>;
    public apiManagementQuestionTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IQuestionType>>(`${this.basePath}/api/management/question-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionsConditionPut(command?: AddQuestionConditionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementQuestionsConditionPut(command?: AddQuestionConditionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementQuestionsConditionPut(command?: AddQuestionConditionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementQuestionsConditionPut(command?: AddQuestionConditionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/questions/condition`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IQuestion>>;
    public apiManagementQuestionsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IQuestion>>>;
    public apiManagementQuestionsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IQuestion>>>;
    public apiManagementQuestionsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IQuestion>>(`${this.basePath}/api/management/questions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionsPatch(command?: UpdateQuestionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementQuestionsPatch(command?: UpdateQuestionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementQuestionsPatch(command?: UpdateQuestionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementQuestionsPatch(command?: UpdateQuestionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/management/questions`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionsPut(command?: AddNewQuestionCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementQuestionsPut(command?: AddNewQuestionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementQuestionsPut(command?: AddNewQuestionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementQuestionsPut(command?: AddNewQuestionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/questions`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiManagementQuestionsSubpipelinePut(command?: AddQuestionToSubPipelineCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiManagementQuestionsSubpipelinePut(command?: AddQuestionToSubPipelineCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiManagementQuestionsSubpipelinePut(command?: AddQuestionToSubPipelineCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiManagementQuestionsSubpipelinePut(command?: AddQuestionToSubPipelineCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/management/questions/subpipeline`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
