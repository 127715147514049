import { WorklistService } from './../../proxy/api/worklist.service';
import { ContractsService } from './../../proxy/api/contracts.service';
import { Component, OnInit } from '@angular/core';
import { AuthService, IContractSubPipeLine, MetaService } from 'src/app/proxy';
import { HttpClient } from '@angular/common/http';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
  contractStatus = [
    {
      'statusId': 0,
      'state': 'complete',
      'label': 'Pending'
    },
    {
      'statusId': 20,
      'state': 'complete',
      'label': 'Initial Admin Review'
    },
    {

      'statusId': 30,
      'state': 'in-progress',
      'label': 'Internal Review'
    },
    {
      'statusId': 40,
      'state': 'todo',
      'label': 'Final Admin Review'
    },
    {
      'statusId': 50,
      'state': 'todo',
      'label': 'Closed'
    }
  ];

  statusOptions = [
    { name: 'Pending Initial Admin Review', value: '20' },
    { name: 'Pending Internal Approal', value: '30' },
    { name: 'Pending Confirmation', value: '35' }
  ];

  workflowStatusOptions = [
    {
      name: 'Pending Submission',
      value: '0'
    },
    {
      name: 'Pending Initial Admin Review',
      value: '20'
    },
    {
      name: 'Pending Internal Review',
      value: '30'
    },
    {
      name: 'Pending Confirmation',
      value: '35'
    },
    {
      name: 'Pending Final Admin Review',
      value: '40'
    }
  ];

  pipelineOptions = [
    {
      name: 'An Order',
      value: '2'
    },
    {
      name: 'Modified Multiple Use Template',
      value: '3'
    },
    {
      name: 'Counterparty Paper',
      value: '4'
    },
    {
      name: 'Amazon Custom Agreement',
      value: '5'
    },
    {
      name: 'Multiple Use Template',
      value: '6'
    },
    {
      name: 'Other/Request fo Guidance',
      value: '7'
    },
    {
      name: 'Entity Confirmation',
      value: '8'
    },
    {
      name: 'Real Estate Agreement',
      value: '9'
    }
  ];

  allowedPipelineOptions = [];

  whoami: any;
  contractKeyId: string = null;
  contract: any;
  subPipelineOptions: any[] = [];
  pipelineId: number = null;
  subPipelineId: number = null;
  statusindicator: string;
  statusId = 20;
  workflowStatusId = 20;
  errorMessage: string;
  contracts = [];
  constructor(private authApi: AuthService,
    private contractApi: ContractsService,
    private metaApi: MetaService,
    private http: HttpClient,
    private worklistApi: WorklistService) { }

  ngOnInit() {
    this.authApi.apiAuthWhoamiGet()
      .subscribe(data => {
        this.whoami = data;
      });

    this.worklistApi.apiWorklistAdminGet(null, 3)
      .subscribe(data => {
        this.contracts = data;
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

  contractKeyIdChanged(event: any) {
    this.contractKeyId = (event.detail && event.detail.value) || event.target.value;
    console.log(this.contractKeyId);
  }

  onStatusChanged(event: any) {
    this.statusId = event.target.value;
  }

  onWorkflowStatusChanged(event: any) {
    this.workflowStatusId = event.target.value;
  }

  onPipelineIdChanged(event: any) {
    this.subPipelineId = null;
    this.subPipelineOptions = [];
    this.pipelineId = event.target.value;
    this.metaApi.apiMetaContractSubPipeLineByContractPipeLineIdGet(this.pipelineId.toString())
      .subscribe(
        result => {
          if (result.length > 1) {
            this.subPipelineOptions = result.map(x => ({ name: x.description, value: x.contractSubPipeLineId.toString() }));
          } else if (result.length === 1) {
            this.subPipelineId = result[0].contractSubPipeLineId;
          }
        }
      );
  }
  onSubPipelineIdChanged(event: any) {
    this.subPipelineId = event.target.value;
  }

  onInquireClicked() {
    this.errorMessage = null;
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.contractApi.apiContractOverviewByContractKeyIdGet(this.contractKeyId)
      .subscribe(data => {
        this.statusindicator = 'success';
        this.contract = data;
        for (let i = 0; i < this.contractStatus.length; i++) {
          const cs = this.contractStatus[i];
          if (cs.statusId < this.contract.statusId) {
            cs.state = 'complete';
          } else if (cs.statusId === this.contract.statusId) {
            cs.state = 'in-progress';
          } else {
            cs.state = 'todo';
          }
        }
        this.pipelineId = this.contract.pipelineId;
        this.getAllowedPipelineOptions(this.pipelineId, this.contract.contractSubPipeLineId);
        this.metaApi.apiMetaContractSubPipeLineByContractPipeLineIdGet(this.pipelineId.toString())
          .subscribe(
            result => {
              if (result.length > 1) {
                this.subPipelineOptions = result.map(x => ({ name: x.description, value: x.contractSubPipeLineId.toString() }));
                this.subPipelineId = this.contract.contractSubPipeLineId;
              } else if (result.length === 1) {
                this.subPipelineOptions = [];
                this.subPipelineId = result[0].contractSubPipeLineId;
              }
            }
          );
        if (this.workflowStatusOptions.map(x => x.value).includes(this.contract.statusId)) {
          this.workflowStatusId = this.contract.statusId;
          this.statusId = this.workflowStatusId;
        }
        const json = JSON.stringify(this.contractStatus);
        this.contractStatus = JSON.parse(json);
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

  private getAllowedPipelineOptions(pipelineId: number, contractSubPipeLineId: number) {
    this.allowedPipelineOptions = [];
    switch (pipelineId) {
      case 9: {
        this.allowedPipelineOptions = this.allowedPipelineOptions.concat(this.pipelineOptions.find(x => x.value === pipelineId.toString()));
        if ([1, 2, 4, 6].includes(contractSubPipeLineId)) {
          this.allowedPipelineOptions = this.allowedPipelineOptions.concat(this.pipelineOptions.find(x => x.value === '8'));
        }
        break;
      }
      default: {
        this.allowedPipelineOptions = this.allowedPipelineOptions.concat(this.pipelineOptions);
        break;
      }
    }
  }

  onRestartClicked() {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/workflow/restart', {
      workflowName: this.contractKeyId,
      statusId: +this.workflowStatusId
    })
      .subscribe(res => {
        console.log(res);
        this.statusindicator = 'success';
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

  onSaveClicked() {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/contract', {
      contractKeyId: this.contractKeyId,
      statusId: this.statusId
    })
      .subscribe(res => {
        this.statusindicator = 'success';
        console.log(res);
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });

  }

  onRestartFunctionalAreaClicked(functionalAreaId: number) {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/subworkflow/restart', {
      workflowName: `${this.contractKeyId}-PendingInternalReview-${functionalAreaId}`,
      statusId: 41
    })
      .subscribe(res => {
        console.log(res);
        this.statusindicator = 'success';
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

  onReclassifyCLicked() {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/contract/updatePipeline', {
      workflowName: this.contractKeyId,
      fromPipelineId: this.contract.pipelineId,
      toPipelineId: this.pipelineId,
      toSubpipelineId: this.subPipelineId
    }).pipe(delay(5000))
      .subscribe(res => {
        console.log(res);
        this.contractApi.apiContractAdminRefreshByContractKeyIdGet(this.contractKeyId)
          .subscribe(response => {
            console.log(response);
            this.statusindicator = 'success';
          }, err => {
            console.error(err);
            this.statusindicator = 'error';
            this.errorMessage = JSON.parse(err._body).message;
          });
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }
}
