/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AggregateFunction } from './aggregateFunction';


export interface GroupDescriptor {
    memberType?: string;
    displayContent?: any;
    aggregateFunctions?: Array<AggregateFunction>;
    member?: string;
    sortDirection?: GroupDescriptor.SortDirectionEnum;
}
export namespace GroupDescriptor {
    export type SortDirectionEnum = 0 | 1;
    export const SortDirectionEnum = {
        NUMBER_0: 0 as SortDirectionEnum,
        NUMBER_1: 1 as SortDirectionEnum
    }
}
