/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { IApproverCC } from '../model/iApproverCC';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApproverCCService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApproverCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IApproverCC>>;
    public apiApproverCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IApproverCC>>>;
    public apiApproverCarbonCopyByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IApproverCC>>>;
    public apiApproverCarbonCopyByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiApproverCarbonCopyByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IApproverCC>>(`${this.basePath}/api/approverCarbonCopy/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approverCarbonCopyUserId 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApproverCarbonCopyDelete(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiApproverCarbonCopyDelete(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiApproverCarbonCopyDelete(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiApproverCarbonCopyDelete(approverCarbonCopyUserId?: string, contractKeyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approverCarbonCopyUserId !== undefined) {
            queryParameters = queryParameters.set('ApproverCarbonCopyUserId', <any>approverCarbonCopyUserId);
        }
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('ContractKeyId', <any>contractKeyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/api/approverCarbonCopy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approverCarbonCopyUserId 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApproverCarbonCopyPost(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiApproverCarbonCopyPost(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiApproverCarbonCopyPost(approverCarbonCopyUserId?: string, contractKeyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiApproverCarbonCopyPost(approverCarbonCopyUserId?: string, contractKeyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approverCarbonCopyUserId !== undefined) {
            queryParameters = queryParameters.set('ApproverCarbonCopyUserId', <any>approverCarbonCopyUserId);
        }
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('ContractKeyId', <any>contractKeyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/approverCarbonCopy`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
