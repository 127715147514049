/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { CancelContractCommand } from '../model/cancelContractCommand';
import { RealEstateAgreementOverView } from '../model/realEstateAgreementOverView';
import { SubmissionContractOverview } from '../model/submissionContractOverview';
import { SubmitDraftContractCommand } from '../model/submitDraftContractCommand';
import { SubmitDraftContractCommandV2 } from '../model/submitDraftContractCommandV2';
import { SubmitEntityConfirmationContractCommand } from '../model/submitEntityConfirmationContractCommand';
import { SubmitEntityConfirmationContractCommandV2 } from '../model/submitEntityConfirmationContractCommandV2';
import { SubmitRealEstateAggreementContractCommand } from '../model/submitRealEstateAggreementContractCommand';
import { UpdateContractSubmissionDraftCommand } from '../model/updateContractSubmissionDraftCommand';
import { UpdateContractSubmissionDraftCommandV2 } from '../model/updateContractSubmissionDraftCommandV2';
import { UpdateEntityConfirmationContractCommand } from '../model/updateEntityConfirmationContractCommand';
import { UpdateEntityConfirmationContractCommandV2 } from '../model/updateEntityConfirmationContractCommandV2';
import { UpdateRealEstateAggreementContractCommand } from '../model/updateRealEstateAggreementContractCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SubmitterContractService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets the submission contract overview. for submitter and submitter cc
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiSubmitterContractByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiSubmitterContractByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiSubmitterContractByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiSubmitterContractByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SubmissionContractOverview>(`${this.basePath}/api/submitter/Contract/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submitter can cancels his own contract.
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractDelete(command?: CancelContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractDelete(command?: CancelContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractDelete(command?: CancelContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractDelete(command?: CancelContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/submitter/Contract`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows a submitter to update an entity confirmation request without changing its status.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractEntityConfirmationContractPatch(command?: UpdateEntityConfirmationContractCommand, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiSubmitterContractEntityConfirmationContractPatch(command?: UpdateEntityConfirmationContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiSubmitterContractEntityConfirmationContractPatch(command?: UpdateEntityConfirmationContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiSubmitterContractEntityConfirmationContractPatch(command?: UpdateEntityConfirmationContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<SubmissionContractOverview>(`${this.basePath}/api/submitter/Contract/entityConfirmationContract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits the entity confirmation request.  Run submission validation  Save contract submission  Kick off contract review workflow
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractEntityConfirmationContractPut(command?: SubmitEntityConfirmationContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractEntityConfirmationContractPut(command?: SubmitEntityConfirmationContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractEntityConfirmationContractPut(command?: SubmitEntityConfirmationContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractEntityConfirmationContractPut(command?: SubmitEntityConfirmationContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/submitter/Contract/entityConfirmationContract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows a submitter to update an entity confirmation request without changing its status.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractEntityConfirmationContractV2Patch(command?: UpdateEntityConfirmationContractCommandV2, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiSubmitterContractEntityConfirmationContractV2Patch(command?: UpdateEntityConfirmationContractCommandV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiSubmitterContractEntityConfirmationContractV2Patch(command?: UpdateEntityConfirmationContractCommandV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiSubmitterContractEntityConfirmationContractV2Patch(command?: UpdateEntityConfirmationContractCommandV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<SubmissionContractOverview>(`${this.basePath}/api/submitter/Contract/entityConfirmationContractV2`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits the entity confirmation request.  Save contract submission  Kick off contract review workflow
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractEntityConfirmationContractV2Put(command?: SubmitEntityConfirmationContractCommandV2, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractEntityConfirmationContractV2Put(command?: SubmitEntityConfirmationContractCommandV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractEntityConfirmationContractV2Put(command?: SubmitEntityConfirmationContractCommandV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractEntityConfirmationContractV2Put(command?: SubmitEntityConfirmationContractCommandV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/submitter/Contract/entityConfirmationContractV2`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits the Real Estate Agreement request.  Run submission validation  Save contract submission  Kick off contract review workflow
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractRealEstateAgreementOverViewPut(command?: SubmitRealEstateAggreementContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractRealEstateAgreementOverViewPut(command?: SubmitRealEstateAggreementContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractRealEstateAgreementOverViewPut(command?: SubmitRealEstateAggreementContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractRealEstateAgreementOverViewPut(command?: SubmitRealEstateAggreementContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/submitter/Contract/realEstateAgreementOverView`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows a submitter to update an entity confirmation request without changing its status.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractRealestateAgreementContractPatch(command?: UpdateRealEstateAggreementContractCommand, observe?: 'body', reportProgress?: boolean): Observable<RealEstateAgreementOverView>;
    public apiSubmitterContractRealestateAgreementContractPatch(command?: UpdateRealEstateAggreementContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RealEstateAgreementOverView>>;
    public apiSubmitterContractRealestateAgreementContractPatch(command?: UpdateRealEstateAggreementContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RealEstateAgreementOverView>>;
    public apiSubmitterContractRealestateAgreementContractPatch(command?: UpdateRealEstateAggreementContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<RealEstateAgreementOverView>(`${this.basePath}/api/submitter/Contract/realestateAgreementContract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows a submitter to update the contract without changing its status.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractSubmissionPatch(command?: UpdateContractSubmissionDraftCommand, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiSubmitterContractSubmissionPatch(command?: UpdateContractSubmissionDraftCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiSubmitterContractSubmissionPatch(command?: UpdateContractSubmissionDraftCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiSubmitterContractSubmissionPatch(command?: UpdateContractSubmissionDraftCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<SubmissionContractOverview>(`${this.basePath}/api/submitter/Contract/submission`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits the contract.  Run submission validation  Save contract submission  Kick off contract review workflow
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractSubmissionPut(command?: SubmitDraftContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractSubmissionPut(command?: SubmitDraftContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractSubmissionPut(command?: SubmitDraftContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractSubmissionPut(command?: SubmitDraftContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/submitter/Contract/submission`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows a submitter to update the contract without changing its status.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractSubmissionV2Patch(command?: UpdateContractSubmissionDraftCommandV2, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiSubmitterContractSubmissionV2Patch(command?: UpdateContractSubmissionDraftCommandV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiSubmitterContractSubmissionV2Patch(command?: UpdateContractSubmissionDraftCommandV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiSubmitterContractSubmissionV2Patch(command?: UpdateContractSubmissionDraftCommandV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<SubmissionContractOverview>(`${this.basePath}/api/submitter/Contract/submissionV2`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submits the contract.  Run submission validation  Save contract submission  Kick off contract review workflow
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSubmitterContractSubmissionV2Put(command?: SubmitDraftContractCommandV2, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiSubmitterContractSubmissionV2Put(command?: SubmitDraftContractCommandV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiSubmitterContractSubmissionV2Put(command?: SubmitDraftContractCommandV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiSubmitterContractSubmissionV2Put(command?: SubmitDraftContractCommandV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/submitter/Contract/submissionV2`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
