/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AddImpactAreaCarbonCopyCommand } from '../model/addImpactAreaCarbonCopyCommand';
import { ApprovalCommand } from '../model/approvalCommand';
import { CancelContractCommand } from '../model/cancelContractCommand';
import { DenyApprovalCommand } from '../model/denyApprovalCommand';
import { FinalApproveContractCommand } from '../model/finalApproveContractCommand';
import { FinalRejectContractCommand } from '../model/finalRejectContractCommand';
import { IAdminContractOverview } from '../model/iAdminContractOverview';
import { IApprovalComments } from '../model/iApprovalComments';
import { IApprovalOverview } from '../model/iApprovalOverview';
import { IContract } from '../model/iContract';
import { IFinalApprovalContractOverview } from '../model/iFinalApprovalContractOverview';
import { IImpactAreaOverview } from '../model/iImpactAreaOverview';
import { IMutDbReportContract } from '../model/iMutDbReportContract';
import { ReSubmitForInternalApprovalCommand } from '../model/reSubmitForInternalApprovalCommand';
import { RemoveImpactAreaCarbonCopyByIdCommand } from '../model/removeImpactAreaCarbonCopyByIdCommand';
import { SkipForFinalApprovalCommand } from '../model/skipForFinalApprovalCommand';
import { SubmissionContractOverview } from '../model/submissionContractOverview';
import { SubmitForFinalApprovalCommand } from '../model/submitForFinalApprovalCommand';
import { SubmitForInternalApprovalCommand } from '../model/submitForInternalApprovalCommand';
import { SubmitterRequestNewContractCommand } from '../model/submitterRequestNewContractCommand';
import { UpdateAdminContractCommand } from '../model/updateAdminContractCommand';
import { UpdateApprovalCommand } from '../model/updateApprovalCommand';
import { UpdateRequestCommentCommand } from '../model/updateRequestCommentCommand';
import { UpdateSubmitterCarbonCopiesCommand } from '../model/updateSubmitterCarbonCopiesCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ContractsService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractAdminRefreshByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiContractAdminRefreshByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiContractAdminRefreshByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiContractAdminRefreshByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractAdminRefreshByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IAdminContractOverview>(`${this.basePath}/api/contract/admin-refresh/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approver approves a contract for all functional areas the approver is assigned to.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalApprovePut(command?: ApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractApprovalApprovePut(command?: ApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractApprovalApprovePut(command?: ApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractApprovalApprovePut(command?: ApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/approval/approve`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param rowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalByContractKeyIdByRowIdGet(contractKeyId: string, rowId: number, observe?: 'body', reportProgress?: boolean): Observable<IApprovalOverview>;
    public apiContractApprovalByContractKeyIdByRowIdGet(contractKeyId: string, rowId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IApprovalOverview>>;
    public apiContractApprovalByContractKeyIdByRowIdGet(contractKeyId: string, rowId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IApprovalOverview>>;
    public apiContractApprovalByContractKeyIdByRowIdGet(contractKeyId: string, rowId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractApprovalByContractKeyIdByRowIdGet.');
        }
        if (rowId === null || rowId === undefined) {
            throw new Error('Required parameter rowId was null or undefined when calling apiContractApprovalByContractKeyIdByRowIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IApprovalOverview>(`${this.basePath}/api/contract/approval/${encodeURIComponent(String(contractKeyId))}/${encodeURIComponent(String(rowId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the approval contract overview. for approver and approver cc
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IApprovalOverview>;
    public apiContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IApprovalOverview>>;
    public apiContractApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IApprovalOverview>>;
    public apiContractApprovalByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractApprovalByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IApprovalOverview>(`${this.basePath}/api/contract/approval/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the list of distinct review comments.
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalCommmentsByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IApprovalComments>>;
    public apiContractApprovalCommmentsByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IApprovalComments>>>;
    public apiContractApprovalCommmentsByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IApprovalComments>>>;
    public apiContractApprovalCommmentsByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractApprovalCommmentsByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IApprovalComments>>(`${this.basePath}/api/contract/approval/commments/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Approver denies a contract for all functional areas the approver is assigned to.   This will also update other approvers that share the same functioanl area.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalDenyPut(command?: DenyApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractApprovalDenyPut(command?: DenyApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractApprovalDenyPut(command?: DenyApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractApprovalDenyPut(command?: DenyApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/approval/deny`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an approver to update the contract without changing its status.  This will also update other approvers that share the same functioanl area.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractApprovalPatch(command?: UpdateApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<IApprovalOverview>;
    public apiContractApprovalPatch(command?: UpdateApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IApprovalOverview>>;
    public apiContractApprovalPatch(command?: UpdateApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IApprovalOverview>>;
    public apiContractApprovalPatch(command?: UpdateApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<IApprovalOverview>(`${this.basePath}/api/contract/approval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractByContractKeyIdGet(contractKeyId: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: any; }>;
    public apiContractByContractKeyIdGet(contractKeyId: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: any; }>>;
    public apiContractByContractKeyIdGet(contractKeyId: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: any; }>>;
    public apiContractByContractKeyIdGet(contractKeyId: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractByContractKeyIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: any; }>(`${this.basePath}/api/contract/${encodeURIComponent(String(contractKeyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Admin can Cancels the contract.
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractDelete(command?: CancelContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractDelete(command?: CancelContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractDelete(command?: CancelContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractDelete(command?: CancelContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/contract`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the final approval contract overview.
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractFinalApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IFinalApprovalContractOverview>;
    public apiContractFinalApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IFinalApprovalContractOverview>>;
    public apiContractFinalApprovalByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IFinalApprovalContractOverview>>;
    public apiContractFinalApprovalByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractFinalApprovalByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IFinalApprovalContractOverview>(`${this.basePath}/api/contract/finalApproval/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Final Approver to reject the contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractFinalApprovalDelete(command?: FinalRejectContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractFinalApprovalDelete(command?: FinalRejectContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractFinalApprovalDelete(command?: FinalRejectContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractFinalApprovalDelete(command?: FinalRejectContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/contract/finalApproval`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Final Approver to approve the contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractFinalApprovalPut(command?: FinalApproveContractCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractFinalApprovalPut(command?: FinalApproveContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractFinalApprovalPut(command?: FinalApproveContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractFinalApprovalPut(command?: FinalApproveContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/finalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reserves a the new contract key without inserting a matching row in the database.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractGenerateContractKeyIdPost(observe?: 'body', reportProgress?: boolean): Observable<IContract>;
    public apiContractGenerateContractKeyIdPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IContract>>;
    public apiContractGenerateContractKeyIdPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IContract>>;
    public apiContractGenerateContractKeyIdPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<IContract>(`${this.basePath}/api/contract/generateContractKeyId`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds an impact area CC to a contract.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractImpactAreaCCPatch(command?: AddImpactAreaCarbonCopyCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiContractImpactAreaCCPatch(command?: AddImpactAreaCarbonCopyCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiContractImpactAreaCCPatch(command?: AddImpactAreaCarbonCopyCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiContractImpactAreaCCPatch(command?: AddImpactAreaCarbonCopyCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/contract/impactAreaCC`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractImpactAreaCarbonCopyDelete(command?: RemoveImpactAreaCarbonCopyByIdCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractImpactAreaCarbonCopyDelete(command?: RemoveImpactAreaCarbonCopyByIdCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractImpactAreaCarbonCopyDelete(command?: RemoveImpactAreaCarbonCopyByIdCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractImpactAreaCarbonCopyDelete(command?: RemoveImpactAreaCarbonCopyByIdCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.delete<boolean>(`${this.basePath}/api/contract/impactAreaCarbonCopy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param includeContractOverview 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractImpactareaByContractKeyIdGet(contractKeyId: string, includeContractOverview: boolean, observe?: 'body', reportProgress?: boolean): Observable<IImpactAreaOverview>;
    public apiContractImpactareaByContractKeyIdGet(contractKeyId: string, includeContractOverview: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IImpactAreaOverview>>;
    public apiContractImpactareaByContractKeyIdGet(contractKeyId: string, includeContractOverview: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IImpactAreaOverview>>;
    public apiContractImpactareaByContractKeyIdGet(contractKeyId: string, includeContractOverview: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractImpactareaByContractKeyIdGet.');
        }
        if (includeContractOverview === null || includeContractOverview === undefined) {
            throw new Error('Required parameter includeContractOverview was null or undefined when calling apiContractImpactareaByContractKeyIdGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeContractOverview !== undefined) {
            queryParameters = queryParameters.set('includeContractOverview', <any>includeContractOverview);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IImpactAreaOverview>(`${this.basePath}/api/contract/impactarea/${encodeURIComponent(String(contractKeyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractMutPipelineContractsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IMutDbReportContract>>;
    public apiContractMutPipelineContractsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IMutDbReportContract>>>;
    public apiContractMutPipelineContractsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IMutDbReportContract>>>;
    public apiContractMutPipelineContractsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<Array<IMutDbReportContract>>(`${this.basePath}/api/contract/mutPipelineContracts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the contract with all fields needed for the Admin edit screen.
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiContractOverviewByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiContractOverviewByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractOverviewByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IAdminContractOverview>(`${this.basePath}/api/contract/overview/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save draft for Admin initial review, final review and any other state
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractOverviewPatch(command?: UpdateAdminContractCommand, observe?: 'body', reportProgress?: boolean): Observable<IAdminContractOverview>;
    public apiContractOverviewPatch(command?: UpdateAdminContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IAdminContractOverview>>;
    public apiContractOverviewPatch(command?: UpdateAdminContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IAdminContractOverview>>;
    public apiContractOverviewPatch(command?: UpdateAdminContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<IAdminContractOverview>(`${this.basePath}/api/contract/overview`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates the new contract.
     * 
     * @param command The command.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractPost(command?: SubmitterRequestNewContractCommand, observe?: 'body', reportProgress?: boolean): Observable<IContract>;
    public apiContractPost(command?: SubmitterRequestNewContractCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IContract>>;
    public apiContractPost(command?: SubmitterRequestNewContractCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IContract>>;
    public apiContractPost(command?: SubmitterRequestNewContractCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<IContract>(`${this.basePath}/api/contract`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to resubmit the contract to approvers.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractReSubmitForInternalApprovalPut(command?: ReSubmitForInternalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/reSubmitForInternalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows an admin to update the RequestComment field.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractRequestCommentPatch(command?: UpdateRequestCommentCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/contract/requestComment`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSearchDetailByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<IApprovalOverview>;
    public apiContractSearchDetailByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IApprovalOverview>>;
    public apiContractSearchDetailByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IApprovalOverview>>;
    public apiContractSearchDetailByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractSearchDetailByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IApprovalOverview>(`${this.basePath}/api/contract/searchDetail/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends any pending notifications for the indicated contract.
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSendNotificationsByContractKeyIdPost(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractSendNotificationsByContractKeyIdPost(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractSendNotificationsByContractKeyIdPost(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractSendNotificationsByContractKeyIdPost(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractSendNotificationsByContractKeyIdPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/contract/sendNotifications/${encodeURIComponent(String(contractKeyId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to submit the contract to approvers.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSkipForFinalApprovalCommandPut(command?: SkipForFinalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractSkipForFinalApprovalCommandPut(command?: SkipForFinalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractSkipForFinalApprovalCommandPut(command?: SkipForFinalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractSkipForFinalApprovalCommandPut(command?: SkipForFinalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/skipForFinalApprovalCommand`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the submission contract overview. for submitter and submitter cc
     * 
     * @param contractKeyId The contract key identifier.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSubmissionByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<SubmissionContractOverview>;
    public apiContractSubmissionByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubmissionContractOverview>>;
    public apiContractSubmissionByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubmissionContractOverview>>;
    public apiContractSubmissionByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiContractSubmissionByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<SubmissionContractOverview>(`${this.basePath}/api/contract/submission/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to submit the contract for final approval.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractSubmitForFinalApprovalPut(command?: SubmitForFinalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/submitForFinalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allows the Admin to submit the contract to approvers.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractSubmitForInternalApprovalPut(command?: SubmitForInternalApprovalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/submitForInternalApproval`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replaces thes list of submitter carbon copies.
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiContractSubmitterCarbonCopyPut(command?: UpdateSubmitterCarbonCopiesCommand, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiContractSubmitterCarbonCopyPut(command?: UpdateSubmitterCarbonCopiesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiContractSubmitterCarbonCopyPut(command?: UpdateSubmitterCarbonCopiesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiContractSubmitterCarbonCopyPut(command?: UpdateSubmitterCarbonCopiesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<boolean>(`${this.basePath}/api/contract/submitterCarbonCopy`,
            command,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
