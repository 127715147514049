/**
 * TaxCAT
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v7
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { IUser } from '../model/iUser';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param roleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthExternalreviewerByRoleIdGet(roleId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAuthExternalreviewerByRoleIdGet(roleId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAuthExternalreviewerByRoleIdGet(roleId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAuthExternalreviewerByRoleIdGet(roleId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling apiAuthExternalreviewerByRoleIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/auth/externalreviewer/${encodeURIComponent(String(roleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Indicates if the current user has one or more of the indicated roles for the specified contract.
     * 
     * @param contractKeyId 
     * @param requiredRoles One or more of: approver, approvercc, impactareaowner, impactareacc, submitter, submittercc
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthIsauthorizedGet(contractKeyId?: string, requiredRoles?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiAuthIsauthorizedGet(contractKeyId?: string, requiredRoles?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiAuthIsauthorizedGet(contractKeyId?: string, requiredRoles?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiAuthIsauthorizedGet(contractKeyId?: string, requiredRoles?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractKeyId !== undefined) {
            queryParameters = queryParameters.set('contractKeyId', <any>contractKeyId);
        }
        if (requiredRoles) {
            requiredRoles.forEach((element) => {
                queryParameters = queryParameters.append('requiredRoles', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/auth/isauthorized`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractKeyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthPrimaryContractRoleByContractKeyIdGet(contractKeyId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiAuthPrimaryContractRoleByContractKeyIdGet(contractKeyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiAuthPrimaryContractRoleByContractKeyIdGet(contractKeyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiAuthPrimaryContractRoleByContractKeyIdGet(contractKeyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractKeyId === null || contractKeyId === undefined) {
            throw new Error('Required parameter contractKeyId was null or undefined when calling apiAuthPrimaryContractRoleByContractKeyIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/auth/primaryContractRole/${encodeURIComponent(String(contractKeyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the current user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAuthWhoamiGet(observe?: 'body', reportProgress?: boolean): Observable<IUser>;
    public apiAuthWhoamiGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IUser>>;
    public apiAuthWhoamiGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IUser>>;
    public apiAuthWhoamiGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.get<IUser>(`${this.basePath}/api/auth/whoami`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
