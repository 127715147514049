import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/proxy';
import { HttpClient } from '@angular/common/http';

const allowedRoles = ["admin", "admin_acct"]
@Component({
  selector: 'app-acct-workflow',
  templateUrl: './acct-workflow.component.html',
  styleUrls: ['./acct-workflow.component.scss']
})
export class AcctWorkflowComponent implements OnInit {
  whoami: any;
  allowed: boolean = false;
  contractKeyId: string = null;
  apiResponse: any;
  statusindicator: string;
  errorMessage: string;
  contracts = [];
  constructor(private authApi: AuthService,
    private http: HttpClient) { }

  ngOnInit() {
    this.authApi.apiAuthWhoamiGet()
      .subscribe(data => {
        this.whoami = data;
        if (data.roles.find(x => allowedRoles.includes(x)).length > 0) {
          this.allowed = true;
        }
        else {
          this.errorMessage = `${data.displayName} is not this.allowed. Allowe roles: ${allowedRoles}`
        }
      });
  }

  contractKeyIdChanged(event: any) {
    this.contractKeyId = ((event.detail && event.detail.value) || event.target.value).trim();
    console.log(this.contractKeyId);
  }

  onGetAcctClicked() {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/acctWorkflow/get', {
      workflowName: this.contractKeyId
    })
      .subscribe(res => {
        console.log(res);
        const payload = JSON.parse(res['Payload']);
        if ('errorMessage' in payload) {
          this.errorMessage = payload['errorType'];
          this.statusindicator = 'error';
          this.apiResponse = payload['trace'];
        }
        else {
          this.apiResponse = payload;
          this.statusindicator = 'success';
        }
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

  onRestartAcctClicked() {
    if (!this.contractKeyId) {
      this.errorMessage = 'Please enter a Contract Key Id';
      return;
    }
    this.statusindicator = 'loading';
    this.errorMessage = null;
    this.http.put('/api/acctWorkflow/restart', {
      workflowName: this.contractKeyId
    })
      .subscribe(res => {
        console.log(res);
        const payload = JSON.parse(res['Payload']);
        if ('errorMessage' in payload) {
          this.errorMessage = payload['errorType'];
          this.statusindicator = 'error';
          this.apiResponse = payload['trace'];
        }
        else {
          this.apiResponse = JSON.parse(payload['body']);
          this.statusindicator = 'success';
        }
      }, err => {
        console.error(err);
        this.statusindicator = 'error';
        this.errorMessage = JSON.parse(err._body).message;
      });
  }

}
