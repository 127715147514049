import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tech-admin',
  templateUrl: './tech-admin.component.html',
  styleUrls: ['./tech-admin.component.scss']
})
export class TechAdminComponent implements OnInit {

  output = {};
  text = '';
  steps = '';
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.steps = JSON.stringify([{
      'state': 'complete',
      'label': 'First Step'
    },
    {
      'state': 'complete',
      'label': 'Second Step'
    },
    {
      'state': 'in-progress',
      'label': 'Third Step'
    },
    {
      'state': 'todo',
      'label': 'Fourth Step'
    }]);
  }

  invokeGet() {
    this.http.get('/api/contract/2018-1234')
      .subscribe(res => {
        console.log(res);
        this.output = res;
      });
  }

  invokePost() {
    this.http.post('/api/contract', {
      body: this.text
    })
      .subscribe(res => {
        console.log(res);
        this.output = res;
      });
  }
}
